import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import Page from '../../components/page'
import withRoot from '../../withRoot'

const styles = theme => ({
  paragraph: {
    margin: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.light,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
})

@withRoot
@withStyles(styles)
export default class TermOfUse extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <Page toggleTheme={this.props.toggleTheme} title="Refund Policy">
        <Typography className={classes.paragraph} align="justify">
          Thank you for using FitFace.
        </Typography>
        <Typography className={classes.paragraph} align="justify">
          This is the Refund Policy of fitface.xyz. Please read this carefully.
        </Typography>
        <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Clockface design</Typography>
        <Typography className={classes.paragraph} align="justify">
          We are so convinced you will absolutely love our clockfaces.
          If you are not satisfied, you have up to 15 days to ask for a refund after the purchase of the design.
          Designers are not able to perform the refund themselves, so please contact FitFace at support@fitface.xyz directly or using our contact form (by clicking the ? button at the bottom of the page) if you want a refund.
        </Typography>

        <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Premium</Typography>
        <Typography className={classes.paragraph} align="justify">
          Premium subscription is non refundable.
        </Typography>

        <Typography className={classes.paragraph} align="justify" variant="h4" color="primary">Contacting us</Typography>
        <Typography className={classes.paragraph} align="justify">
          If you would like to contact us concerning any matter relating to this Refund Policy, you may send an email to support@fitface.xyz or use our contact form by clicking the ? button at the bottom of the page.
        </Typography>
      </Page>
    )
  }
}
